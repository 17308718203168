import { CS_SUPPORTED_COUNTRY, SUPPORTED_COUNTRY } from "../config/appConfigConstants";
import { hideTaskOnConfig, TaskGroup } from "../config/tasks";
import { buildUrl } from "../config/urls";
import { Stage } from "../helpers/get-settings";

type Country = SUPPORTED_COUNTRY | CS_SUPPORTED_COUNTRY;

type AvailableTasks = {
  preNheTasks: TaskGroup;
  preTrainingTasks?: TaskGroup;
  preDayOneTasks: TaskGroup;
};

export type AvailableTasksConfiguration = {
  "Customer Service": Overrides;
  Alumni: Overrides;
  [country: string]: Overrides;
  defaultValue: Overrides;
};

type Overrides = {
  countryList?: Country[];
  overrideValue: {
    preDayOneTasks: TaskGroup;
    preNheTasks?: TaskGroup;
    preTrainingTasks?: TaskGroup;
  };
};

export const createGroup = (
  taskGroup?: TaskGroup,
  stage?: Stage,
  requisitionId?: string,
  applicationId?: string,
  candidateId?: string
) => ({
  ...taskGroup,
  tasks: taskGroup?.tasks?.filter(hideTaskOnConfig).map((task) => ({
    ...task,
    titleStatusPath:
      task.titleStatusPath &&
      buildUrl(task.titleStatusPath, {
        stage,
        requisitionId,
        applicationId,
        candidateId,
      }),
  })),
});
