import React, { useState, useEffect } from "react";
import { H2, Text } from "../../../../../components/typography";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { UploadCard } from "../upload-card";
import { Input, InputFooter } from "@amzn/stencil-react-components/form";
import { useChecklistArb } from "../../../../../hooks/use-arb";
import { AttachmentType, UploadDetails, AttachmentSubType } from "../../models";
import { submitDocument } from "../../requests/requests";
import { useParams } from "react-router-dom";
import { deleteCachedRequest } from "../../../../../utility/candidate-data";
import { MessageBanner, MessageBannerType } from "@amzn/stencil-react-components/message-banner";
import {
  useGetCandidateDataQuery,
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
  useUpdateCandidateDocumentIdToCDSMutation,
} from "../../../../../reduxStore/api/apiSlice";
import { dispatchOnDev } from "../../../../../utility/dev-env-helpers";
import { useBackToMainPage } from "../../helpers/useBackToMainPage.hook";
import { TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR } from "../../../../../utility/constants/common";
import { useTemporarySubmitDisabledSolution } from "../../helpers/useTemporarySubmitDisabledSolution.hook";
import { Label } from "@amzn/stencil-react-components/text";
import { DebounceButton } from "../../../../../components/common/DebounceButton";

export function TaxIdUploadLayout() {
  const { requisitionId, applicationId } = useParams<{
    requisitionId: string;
    applicationId: string;
  }>();

  const { data: candidateData, refetch } = useGetCandidateDataQuery({ applicationId });
  const [updateCandidateDocumentID] = useUpdateCandidateDocumentIdToCDSMutation();
  const [updateDocMetadataToCDS] = useUpdateCDSDocumentUploadMetadataMutation();

  const [getPresignedURL] = useGetPresignedURLToCDSMutation();
  const [submitDocToCDS] = useSubmitDocToCDSFromPresignedURLMutation();

  const bundle = useChecklistArb();
  const taxIdTitleMessage = bundle.getMessage("Checklist-Tax-ID-Title");
  const taxIdTextMessage = bundle.getMessage("Checklist-Tax-ID-Text");
  const taxIdNumberMessage = bundle.getMessage("Checklist-Tax-ID-Number-Title");
  const taxIdNumberMessageError = bundle.getMessage("Checklist-Tax-ID-Text-Error");
  const imageSubmitMessage = bundle.getMessage("Checklist-Document-Image-Submit-ButtonText");
  const maximumFileSizeMessage = bundle.getMessage("Checklist-Document-Maximum-File-Size-Text");
  const acceptableFileText = bundle.getMessage("Checklist-Document-Acceptable-File-Text");
  const documentUploadDependencyServiceErrorMessage = bundle.getMessage("Checklist-Document-General-Error-Message");
  const documentIdUploadDependencyServiceErrorMessage = bundle.getMessage(
    "Checklist-Document-ID-General-Error-Message"
  );
  const frontErrorMessageBanner = bundle.getMessage("Checklist-Document-General-Error-Front-Message");

  const p0LimitFixMessage = bundle.getMessage("p0LimitFixMessage");
  const [frontDocument, setFrontDocument] = useState<UploadDetails>();
  const [submitButtonToggle, setBlockSubmitButton] = useState<boolean>(true);
  const [fileUploadDependencyServiceErrorMessage, setFileUploadDependencyServiceErrorMessage] = useState<string>();
  const [idUploadDependencyServiceErrorMessage, setIdUploadDependencyServiceErrorMessage] = useState<string>();
  const [taxIdNumber, setTaxIdNumber] = useState<string>("");
  const [errorMessage, setErrorMessage] = useState<string>();
  const [isAriaInvalid, setIsAriaInvalid] = useState<boolean>(false);

  useEffect(() => {
    if (taxIdNumber.length > 0 && !validateTaxNumber.test(taxIdNumber)) {
      setIsAriaInvalid(true);
    } else {
      setIsAriaInvalid(false);
    }
  }, [taxIdNumber]);

  const backToMainPage = useBackToMainPage(refetch);
  useTemporarySubmitDisabledSolution(false);
  const validateTaxNumber = new RegExp("^[a-zA-Z0-9]{13}$");

  useEffect(() => {
    if (frontDocument?.imageBase64 && validateTaxNumber.test(taxIdNumber)) {
      setBlockSubmitButton(false);
    } else {
      setBlockSubmitButton(true);
    }
  }, [frontDocument, taxIdNumber]);

  const updateDocument = async (
    image: string,
    fileName: string,
    contentType: string,
    side: string,
    applicationId?: string
  ) => {
    if (candidateData) {
      const documentData = {
        documentType: AttachmentType.RFC,
        attachmentSubType: side,
        imageBase64: image,
        fileName: fileName,
        contentType: contentType,
        candidateId: candidateData?.candidateId,
      };
      setFrontDocument(documentData);
    } else {
      dispatchOnDev(() => {
        console.error("candidateData is not ready yet");
      });
    }
  };

  const clickEventSubmitDocument = async () => {
    const isValidTaxNumber = validateTaxNumber.test(taxIdNumber);

    if (!frontDocument?.imageBase64) {
      setErrorMessage(frontErrorMessageBanner);
      if (!isValidTaxNumber) setIsAriaInvalid(true);

      document.getElementById("doc-upload-proxy-button")?.blur();
      const input = document.getElementById(`tax-id-upload`);
      input?.focus();

      return;
    } else {
      if (!isValidTaxNumber) {
        if (!isValidTaxNumber) setIsAriaInvalid(true);
        const input = document.getElementById(`taxIdNumber`);
        input?.focus();

        return;
      }
    }

    if (!candidateData) {
      dispatchOnDev(() => {
        console.error("CandidateData is not ready yet");
      });
      return;
    }

    let returnToMainPage = true;
    if (frontDocument && validateTaxNumber.test(taxIdNumber)) {
      try {
        updateCandidateDocumentID({
          candidateId: frontDocument.candidateId || candidateData.candidateId,
          idData: {
            nationalIdType: AttachmentType.RFC,
            idNumber: taxIdNumber,
          },
        });
      } catch (err) {
        console.log(err);
        setIdUploadDependencyServiceErrorMessage(documentIdUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
      try {
        await submitDocument(
          getPresignedURL,
          submitDocToCDS,
          updateDocMetadataToCDS,
          applicationId,
          [frontDocument],
          setBlockSubmitButton
        );
      } catch (err) {
        console.log(err);
        setBlockSubmitButton(false);
        setFileUploadDependencyServiceErrorMessage(documentUploadDependencyServiceErrorMessage);
        returnToMainPage = false;
      }
      deleteCachedRequest(applicationId); // delete stale data so that updated Id loads on home page
      if (returnToMainPage) {
        backToMainPage();
      }
    }

    if (!frontDocument?.imageBase64) {
      setErrorMessage(frontErrorMessageBanner);
      return;
    }
  };

  return (
    <Col display={"flex"} justifyContent={"center"} gridGap={6} width={"100%"}>
      <H2>{taxIdTitleMessage}</H2>
      <Text>
        {taxIdTextMessage} <strong style={{ color: "red" }}>*</strong>{" "}
      </Text>
      <Text>{acceptableFileText}</Text>
      <Text>{maximumFileSizeMessage}</Text>
      <Row alignSelf={"center"} padding={{ top: "10px", bottom: "10px" }} gridGap="S400" width="100%">
        <UploadCard
          side={AttachmentSubType.FRONT}
          documentDetailsFn={updateDocument}
          setErrorMessage={setErrorMessage}
          applicationId={applicationId}
          setDependencyErrorMessage={setFileUploadDependencyServiceErrorMessage}
          attachmentTypeName={taxIdTitleMessage}
          customId={`tax-id-upload`}
          hasError={
            !!fileUploadDependencyServiceErrorMessage || !!errorMessage || !!idUploadDependencyServiceErrorMessage
          }
        />
      </Row>
      {fileUploadDependencyServiceErrorMessage && (
        <MessageBanner type={MessageBannerType.Error}>{fileUploadDependencyServiceErrorMessage}</MessageBanner>
      )}
      {errorMessage && (
        <MessageBanner type={MessageBannerType.Error}>
          <Text id={`tax-id-upload-description`}>{errorMessage}</Text>
        </MessageBanner>
      )}
      {idUploadDependencyServiceErrorMessage ? (
        <MessageBanner type={MessageBannerType.Error}>{idUploadDependencyServiceErrorMessage}</MessageBanner>
      ) : null}

      <Col>
        <Label htmlFor="taxIdNumber">
          {taxIdNumberMessage} <strong style={{ color: "red" }}>*</strong>
        </Label>{" "}
        <Input
          data-testid="tax-id-input"
          id="taxIdNumber"
          aria-required="true"
          placeholder={taxIdNumberMessage}
          maxLength={13}
          onChange={(e) => setTaxIdNumber(e.target.value)}
          type="text"
          aria-invalid={isAriaInvalid ? "true" : "false"}
          aria-describedby="taxIdNumberError"
          error={isAriaInvalid}
        ></Input>
        {isAriaInvalid ? (
          <InputFooter id="taxIdNumberError" error>
            {taxIdNumberMessageError}
          </InputFooter>
        ) : null}
        {/*This place holder text needs to be updated when the message gets added  */}
      </Col>
      <Row padding={{ top: "10px", bottom: "10px" }}>
        <MessageBanner type={MessageBannerType.Informational}>{p0LimitFixMessage}</MessageBanner>
      </Row>
      <DebounceButton
        onClick={clickEventSubmitDocument}
        id={TEMPORARY_SUBMIT_BUTTON_DISABLED_SOLUTION_SELECTOR}
        style={{ visibility: "hidden" }}
      >
        {imageSubmitMessage}
      </DebounceButton>
    </Col>
  );
}
