import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col } from "@amzn/stencil-react-components/layout";
import { PageContainer } from "@amzn/stencil-react-components/page";
import { format } from "date-fns";
import React, { BaseSyntheticEvent, useCallback, useEffect, useRef, useState } from "react";
import { Prompt } from "react-router-dom";
import { H1, Text } from "../typography";
import { HVH_COLORS } from "../../config/palette";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlParams } from "../../hooks/use-url-params";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import {
  useSendMetricVideoRecordAshMutation,
  useSendMetricVideoRecordNonAshMutation,
} from "../../reduxStore/api/apiSlice";
import { dispatchOnDev } from "../../utility/dev-env-helpers";
import { isEnglishLocale } from "../../utility/locale-helper";
import { useGetVideoData } from "../../hooks/apis/useGetVideoData";
import { usePostNewHireVideoStatus } from "../../hooks/apis/usePostNewHireVideoStatus";

export enum ReferrerMode {
  Ash = "ash",
  NonAsh = "non-ash",
}

interface NewHireVideoFlyoutProps {
  isTask: boolean;
}

interface CloseButtonParams {
  onClick: () => void;
}

export const CreateCloseFlyoutButton = ({ onClick }: CloseButtonParams) => {
  const bundle = useChecklistArb();
  return (
    <Button id={"VideoFlyoutCloseButton"} variant={ButtonVariant.Primary} onClick={onClick}>
      {bundle.getMessage("Checklist-General-CloseButtonText")}
    </Button>
  );
};

export const NewHireVideoFlyout = ({ isTask }: NewHireVideoFlyoutProps) => {
  const { referrerMode, requisitionId, applicationId } = useUrlParams();
  const videoData = useGetVideoData();
  usePostNewHireVideoStatus();

  const isEnglish: boolean = isEnglishLocale();
  const videoRef = useRef<HTMLVideoElement>(null);

  const { videoRecordQueryUrl } = useUrlBuilder();
  const { sendNewHireVideoAnalyticsEvent } = useSendAnalyticEvent();

  const bundle = useChecklistArb();
  const nheDetails = bundle.getMessage("Checklist-NHE-Details");
  const nheTitle = bundle.getMessage("Checklist-NHE-H1");

  const [sendMetricVideoRecordAsh] = useSendMetricVideoRecordAshMutation();
  const [sendMetricVideoRecordNonAsh] = useSendMetricVideoRecordNonAshMutation();

  const [watchedTime, setWatchedTime] = useState(0);
  const [currentTime, setCurrentTime] = useState(0);
  const [lastPlay, setLastPlay] = useState(0);
  const [videoPlayedAt, setVideoPlayedAt] = useState("");
  const [linkClickedAt, setLinkClickedAt] = useState("");

  const onLoadedmetadata = (e: BaseSyntheticEvent) => {
    const lastCur = !localStorage.getItem("videoCurrentTime")
      ? 0
      : parseFloat(localStorage.getItem("videoCurrentTime") as string);
    e.target.currentTime = lastCur;
    setCurrentTime(lastCur);
    setLastPlay(lastCur);
    setWatchedTime(0);
  };

  const onVideoTimeUpdate = (e: BaseSyntheticEvent) => {
    if (!e.target.seeking) {
      if (e.target.currentTime > watchedTime) {
        setWatchedTime(e.target.currentTime - lastPlay);
      }
      setCurrentTime(e.target.currentTime);
    }
  };

  const onVideoSeeking = (e: BaseSyntheticEvent) => {
    setLastPlay(e.target.currentTime);
    setCurrentTime(e.target.currentTime);
  };

  const onVideoPlay = () => {
    setVideoPlayedAt((prevPlayedAt) =>
      prevPlayedAt === "" ? format(new Date(), "yyyy-MM-dd HH:mm:ss") : prevPlayedAt
    );
  };

  useEffect(() => {
    const handleVisibilitychange = async () => {
      if (document.visibilityState !== "visible") {
        await sendDataToServer();
      }
    };
    document.addEventListener("visibilitychange", handleVisibilitychange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilitychange);
    };
  });

  const onVideoEnd = useCallback(() => {
    sendNewHireVideoAnalyticsEvent("finish new hire video");
  }, []);

  useEffect(() => {
    sendNewHireVideoAnalyticsEvent("new hire video");
    setLinkClickedAt(format(new Date(), "yyyy-MM-dd HH:mm:ss"));
  }, []);

  const sendDataToServer = async () => {
    if (videoPlayedAt === "") {
      return;
    }

    setLastPlay(currentTime);
    localStorage.setItem("videoCurrentTime", String(currentTime));

    const data = JSON.stringify({
      linkClickedAt: linkClickedAt,
      videoPlayedAt: videoPlayedAt,
      totalWatchTime: String(watchedTime),
      language: "en-us",
      applicationId: applicationId,
    });

    setWatchedTime(0);
    setVideoPlayedAt("");

    if (referrerMode === ReferrerMode.Ash) {
      sendMetricVideoRecordAsh({ applicationId, payload: data });
    } else {
      sendMetricVideoRecordNonAsh({ requisitionId, payload: data });
    }
  };

  const NheVideoComponent = videoData?.uri && (
    <Col gridGap="1rem" padding={"1rem"}>
      {isTask ? "" : <H1>{nheTitle}</H1>}
      <Text>{nheDetails}</Text>
      <video
        ref={videoRef}
        id="newHireVideo"
        data-testid="newHireVideo"
        data-referrerMode={referrerMode}
        width="100%"
        controls
        onPlay={onVideoPlay}
        onEnded={onVideoEnd}
        onSeeking={onVideoSeeking}
        onTimeUpdate={onVideoTimeUpdate}
        onLoadedMetadata={onLoadedmetadata}
        data-test-record-url={videoRecordQueryUrl}
        crossOrigin="anonymous"
        tabIndex={isEnglish ? 0 : undefined}
        controlsList={isEnglish ? undefined : "nodownload noplaybackrate"}
        disablePictureInPicture={isEnglish ? undefined : true}
      >
        <source src={videoData?.uri} type="video/mp4" />
      </video>
    </Col>
  );

  return (
    <>
      <Prompt
        message={(location: { pathname: string }, action: string) => {
          if (action === "POP") {
            sendDataToServer().catch(() => {
              dispatchOnDev(() => {
                console.error("Not able to send video stats to server...");
              });
            });
          }
          return true;
        }}
      />

      {isTask ? (
        <> {NheVideoComponent} </>
      ) : (
        <PageContainer color={HVH_COLORS.NEUTRAL_90}>{NheVideoComponent}</PageContainer>
      )}
    </>
  );
};
