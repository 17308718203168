import { useEffect } from "react";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { useLazyGetInstantAppointmentEligibilityQuery } from "../../reduxStore/api/apiSlice";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { useGetApplicationId } from "./useGetApplicationId";
import { getLocale } from "../../utility/locale-helper";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { isTaskEnabledByAppConfig } from "../../utility/app-config-helper";
import { IS_VIRTUAL_INSTANT_APPOINTMENT_ENABLED } from "../../config/appConfigConstants";

export const useGetInstantAppointmentEligibility = () => {
  const { instantAppointmentEligibility } = useAppSelector(getConfigData);
  const dispatch = useDispatch();
  const applicationId = useGetApplicationId();
  const [getInstantAppointmentEligibility] = useLazyGetInstantAppointmentEligibilityQuery();

  useEffect(() => {
    if (!isTaskEnabledByAppConfig(IS_VIRTUAL_INSTANT_APPOINTMENT_ENABLED, applicationId)) return;
    if (instantAppointmentEligibility) return;

    if (applicationId) {
      getInstantAppointmentEligibility({ applicationId, locale: getLocale() }, true)
        .unwrap()
        .then((data) => {
          dispatch(setConfigData({ key: CONFIG_DATA_KEY.INSTANT_APPOINTMENT_ELIGIBILITY, value: data }));
        });
    }
  }, [applicationId, dispatch, getInstantAppointmentEligibility, instantAppointmentEligibility]);

  return instantAppointmentEligibility;
};
