import { PreloadedState, combineReducers, configureStore } from "@reduxjs/toolkit";
import { logger, throwWhenReduxDispatchSameActionSequentiallyAfterPassingThreshold } from "./middlewares";
import configReducer from "./slices/configSlice";
import docUploadReducer from "./slices/docUploadSlice";
import taskStatusReducer from "./slices/taskStatusSlice";
import { apiSlice } from "./api/apiSlice";

const DISPATCH_THRESHOLD = 500; // Total actions dispatched sequentially

const rootReducer = combineReducers({
  config: configReducer,
  docUpload: docUploadReducer,
  [apiSlice.reducerPath]: apiSlice.reducer,
  taskStatus: taskStatusReducer,
});

const middlewares = [
  apiSlice.middleware,
  throwWhenReduxDispatchSameActionSequentiallyAfterPassingThreshold(DISPATCH_THRESHOLD),
];
export const devMiddlewares = [...middlewares, logger];

export function setupStore(preloadedState?: PreloadedState<RootState>) {
  return configureStore({
    reducer: rootReducer,
    preloadedState,
    middleware: (getDefaultMiddleware) =>
      getDefaultMiddleware().concat(process.env.NODE_ENV === "development" ? devMiddlewares : middlewares),
  });
}

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof rootReducer>;
export type AppStore = ReturnType<typeof setupStore>;
export type AppDispatch = AppStore["dispatch"];
