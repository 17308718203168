import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";

export interface RightToWorkDocumentUploadProps {
  status: StatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const RightToWorkDocumentUploadButton = ({ status, shouldOverrideStatus }: RightToWorkDocumentUploadProps) => {
  const redirect = useCallback(() => {
    // window.open("https://www.google.com/", "_blank");
  }, []);

  const bundle = useChecklistArb();

  return (
    <div>
      {
        <Col>
          <Button
            data-testid="Complete-pre-hire-orientation-button"
            variant={ButtonVariant.Primary}
            onClick={redirect}
            // disabled={}
          >
            {"Upload your Work Auth Documents"}
          </Button>
        </Col>
      }
    </div>
  );
};
