import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ALL_TASKS } from "../../config/taskConfigConstants";
import { StatusEnum } from "../../utility/enums/common";
import { RootState } from "../store";

export type TaskKey = (typeof ALL_TASKS)[number];

type TaskStatusState = Partial<Record<TaskKey, StatusEnum>>;

const initialState: TaskStatusState = {};

const taskStatusSlice = createSlice({
  name: "TaskStatusSlice",
  initialState,
  reducers: {
    _setTaskStatus: (state, action: PayloadAction<{ key: TaskKey; value: StatusEnum }>) => {
      const { key, value } = action.payload;
      state[key] = value;
    },
  },
});

// Wrap setTaskStatus to get proper type check
export const setTaskStatus = (input: { key: TaskKey; value: StatusEnum }) =>
  taskStatusSlice.actions._setTaskStatus(input);

export const getTaskStatus = (state: RootState) => state.taskStatus;

export default taskStatusSlice.reducer;
