import { ADOBE_DATA_LAYER_URL } from "../config/urls";
import { getSettings } from "../helpers/get-settings";
import { ApplicationManageData, JobDetailsModel } from "./application-data";
import { CandidateData } from "./candidate-data";
import { getLocale } from "./locale-helper";
import { isCustomerService } from "./app-config-helper";
import { getAdobeSessionId } from "./new-ui-toggle";

interface MetricsWindow {
  dataLayerArray?: any[];
}

const WORKFLOW = "BB NACF Tier-1 Node Workflow";
const WORKFLOW_CUSTOMER_SERVICE = "CS Hiring Workflow";
const SITE_ID_PREFIX = "SITE-";

const adobeSessionId = getAdobeSessionId();

getSettings().then((settings) => {
  const script = document.createElement("script");
  script.setAttribute("src", ADOBE_DATA_LAYER_URL[settings.stage]);
  script.setAttribute("defer", "");
  script.setAttribute("async", "");
  document.body.appendChild(script);
});

const pushLayer = (window: MetricsWindow, metric: any) => {
  if (process.env.NODE_ENV === "development") {
    console.log(metric);
    console.log(JSON.stringify(metric, null, 2));
  }
  window.dataLayerArray = window.dataLayerArray || [];
  window.dataLayerArray.push(metric);
};

export function isAshReferral(domain: string) {
  return [
    "localhost",
    "checklist.associate.amazondelivers.jobs",
    "iad.checklist.associate.amazondelivers.jobs",
    "gamma.iad.checklist.associate.amazondelivers.jobs",
    "beta.pdx.checklist.associate.amazondelivers.jobs",
  ].includes(domain);
}

export function isBBReferral(domain: string) {
  return [
    "alpha.associate.amazondelivers.jobs",
    "beta.associate.amazondelivers.jobs",
    "gamma.associate.amazondelivers.jobs",
    "associate.amazondelivers.jobs",
  ].includes(domain);
}

export function interpretReferrer(referrer: string) {
  if (window.ASH_CHECKLIST_isChecklistReferral) {
    return "videoASHChecklistReferral";
  }

  if (!referrer) {
    return "videoEmailReferral";
  }

  const referrerHost = new URL(referrer).hostname;
  if (isAshReferral(referrerHost)) {
    return "videoAshChecklistReferral";
  }

  if (isBBReferral(referrerHost)) {
    return "videoBBChecklistReferral";
  }

  return "unknownReferral - " + referrerHost;
}

export function addEventMetric(
  window: MetricsWindow,
  applicationId: string,
  event: string,
  candidateData: CandidateData | undefined | null,
  applicationState: ApplicationManageData | undefined | null,
  jcDetails: JobDetailsModel | undefined | null,
  props?: any
) {
  if (!candidateData || !applicationState || !jcDetails) {
    if (!candidateData) console.error("candidateData is not available");
    if (!applicationState) console.error("applicationState is not available");
    if (!jcDetails) console.error("jcDetails is not available");

    return;
  }

  if (jcDetails?.error || applicationState?.error) {
    if (jcDetails?.error) console.error("error fetching jcDetails");
    if (applicationState?.error) console.error("error fetching applicationManageData");

    return;
  }

  const { sfCandidateId, candidateGlobalId, employmentInfo } = candidateData;

  const metric = {
    application: {
      ID: applicationId,
      workflow: isCustomerService(applicationId) ? WORKFLOW_CUSTOMER_SERVICE : WORKFLOW,
    },
    event: event,
    candidate: {
      adobeSessionId: adobeSessionId,
      CID: sfCandidateId,
      candidateGlobalId: candidateGlobalId,
    },
    job: {
      jobId: jcDetails.jobId,
      site: trimSiteCode(jcDetails.siteId),
      day1Day: jcDetails.firstDayOnSite,
    },
    employmentInfo: {
      employeeId: employmentInfo?.employeeId,
    },
    timeStamp: { time: new Date() },
    page: {
      locale: getLocale(),
      applicationState: applicationState.applicationState,
    },
    ...props,
  };
  pushLayer(window, metric);
}

export async function addEventMetricWithBBId(
  candidateData: CandidateData | undefined | null,
  window: MetricsWindow,
  applicationId: string,
  event: string,
  props?: any
) {
  if (!candidateData) {
    if (!candidateData) console.error("candidateData is not available");
    return;
  }

  const { candidateId } = candidateData;

  const metric = {
    application: {
      ID: applicationId,
      workflow: isCustomerService(applicationId) ? WORKFLOW_CUSTOMER_SERVICE : WORKFLOW,
    },
    event: event,
    candidate: {
      adobeSessionId: adobeSessionId,
      bbCandidateId: candidateId,
    },
    ...props,
  };

  pushLayer(window, metric);
}

export async function addPageLoadMetric(
  candidateData: CandidateData | undefined | null,
  applicationState: ApplicationManageData | undefined | null,
  jcDetails: JobDetailsModel | undefined | null,
  window: MetricsWindow,
  applicationId: string,
  name: string,
  props?: any
) {
  if (!candidateData || !applicationState || !jcDetails) {
    if (!candidateData) console.error("candidateData is not available");
    if (!applicationState) console.error("applicationState is not available");
    if (!jcDetails) console.error("jcDetails is not available");

    return;
  }

  if (applicationState.error || jcDetails.error) {
    if (applicationState?.error) console.error("error fetching applicationState");
    if (jcDetails?.error) console.error("error fetching jcDetails");

    return;
  }

  const { sfCandidateId, candidateGlobalId, employmentInfo } = candidateData;

  const metric = {
    application: {
      ID: applicationId,
      workflow: isCustomerService(applicationId) ? WORKFLOW_CUSTOMER_SERVICE : WORKFLOW,
    },
    event: "page load",
    candidate: {
      adobeSessionId: adobeSessionId,
      CID: sfCandidateId,
      candidateGlobalId: candidateGlobalId,
    },
    job: {
      jobId: jcDetails.jobId,
      site: trimSiteCode(jcDetails.siteId),
      day1Day: jcDetails.firstDayOnSite,
    },
    employmentInfo: {
      employeeId: employmentInfo?.employeeId,
    },
    timeStamp: { time: new Date() },
    page: {
      name: name,
      type: "application",
      locale: getLocale(),
      applicationState: applicationState.applicationState,
    },
    ...props,
  };

  pushLayer(window, metric);
}

// trim additional 'SITE-' prefix for the adobe analytics: https://t.corp.amazon.com/V1565692741
function trimSiteCode(siteCode: string | undefined) {
  if (!!siteCode && siteCode.startsWith(SITE_ID_PREFIX)) {
    return siteCode.slice(SITE_ID_PREFIX.length);
  }

  return siteCode;
}
