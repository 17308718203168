import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";
import { useNavigator } from "../../hooks/use-navigator";
import { COMPLETE_PHO_URL } from "../../config/urls";

export interface PreHireOrientationProps {
  status: StatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const PreHireOrientationButton = ({ status, shouldOverrideStatus }: PreHireOrientationProps) => {
  const [goToPHO] = useNavigator(COMPLETE_PHO_URL);
  // const { sendBadgePhotoEventAnalyticsEvent } = useSendAnalyticEvent();

  const enablePHO = () => {
    // sendBadgePhotoEventAnalyticsEvent("camera enabled");
    goToPHO();
  };

  const bundle = useChecklistArb();

  return (
    <div>
      {
        <Col>
          <Button
            data-testid="Complete-pre-hire-orientation-button"
            variant={ButtonVariant.Primary}
            onClick={enablePHO}
            // disabled={}
          >
            {"Complete pre hire orientation"}
          </Button>
        </Col>
      }
    </div>
  );
};
