import { Flyout, FlyoutContent, FlyoutTitleRenderer } from "@amzn/stencil-react-components/flyout";
import { H2, Text } from "../typography";
import React, { useEffect, useRef, useState } from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { Col, View } from "@amzn/stencil-react-components/layout";
import { useGetRvpWidgetDetails } from "../../hooks/apis/useGetRvpWidgetDetails";
import { Spinner, SpinnerSize } from "@amzn/stencil-react-components/spinner";
import { HVH_COLORS } from "../../config/palette";
import { toString } from "qrcode";
import DOMPurify from "dompurify";

type RvpQrFlyoutProps = {
  isOpen: boolean;
  closeFlyout: () => void;
};

const titleRenderer: FlyoutTitleRenderer = ({ titleText }) => (
  <H2 id="modal-title" color={"#FFFFFF"}>
    {titleText}
  </H2>
);

const RvpQr = () => {
  const rvpWidgetDetails = useGetRvpWidgetDetails();
  const bundle = useChecklistArb();
  const loadingText = bundle.getMessage("checklist-General-TaskStatus-Loading");
  const [qrCodeSvg, setQrCodeSvg] = useState("");

  useEffect(() => {
    if (rvpWidgetDetails?.data) {
      const { connectSourceUrl, encodedToken, elementId, snippetId } = rvpWidgetDetails.data;
      const qrPayload = { u: connectSourceUrl, e: elementId, s: snippetId, t: encodedToken };
      toString(JSON.stringify(qrPayload), {
        type: "svg",
      }).then(setQrCodeSvg);
    }
  }, [rvpWidgetDetails]);

  if (!qrCodeSvg) {
    return (
      <Col alignItems="center" padding={"2rem"} backgroundColor={HVH_COLORS.NEUTRAL_0}>
        <Spinner size={SpinnerSize.Small} loadingText={loadingText} showText={true} data-testid="qr-loading-spinner" />
      </Col>
    );
  }

  return (
    <div data-testid="qr-canvas-wrapper" dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(qrCodeSvg) }}></div>
  );
};

export const RvpQrFlyout = ({ isOpen, closeFlyout }: RvpQrFlyoutProps) => {
  const bundle = useChecklistArb();

  const qrCodeTitleMessage = bundle.getMessage("Checklist-Appointment-Personal-QR-Title");
  const qrCodeDescriptionTitleMessage = bundle.getMessage("Checklist-Appointment-Personal-QR-Description-Title");
  const qrCodeDescriptionMessage = bundle.getMessage("Checklist-Appointment-Personal-QR-Description");

  return (
    <Flyout data-testid="rvp-qr-flyout" isOpen={isOpen} close={closeFlyout} aria-labelledby={"modal-title"}>
      <FlyoutContent titleText={qrCodeTitleMessage} renderTitle={titleRenderer} onCloseButtonClick={closeFlyout}>
        <Col className="flyout-content" gridGap="S300" data-testid="rvp-qr-content">
          <View width="100%">
            <RvpQr />
          </View>
          <H2>{qrCodeDescriptionTitleMessage}</H2>
          <Text>{qrCodeDescriptionMessage}</Text>
        </Col>
      </FlyoutContent>
    </Flyout>
  );
};
