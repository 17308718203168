import React, { useCallback } from "react";
import { AppointmentInfo } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { isEligibleForVirtualInstantAppointment } from "../../helpers/utils";
import { secondsToMinutes } from "date-fns";
import { LinkCard } from "@amzn/stencil-react-components/card";
import { HVH_COLORS } from "../../config/palette";
import { Col, Row } from "@amzn/stencil-react-components/layout";
import { Text } from "@amzn/stencil-react-components/text";
import { IconExternalLink } from "@amzn/stencil-react-components/icons";
import { useChecklistArb } from "../../hooks/use-arb";
import { useUrlBuilder } from "../../hooks/useUrlBuilder.hook";
import { useGetInstantAppointmentEligibility } from "../../hooks/apis/useGetInstantAppointmentEligibility";
import { InstantAppointmentEligibleStatus } from "../../utility/enums/common";
import { useSendAnalyticEvent } from "../../hooks/jobDetailsCardAnalyticEventHelper.hook";

export const InstantAppointmentBanner = ({
  appointmentV2Details,
  applicationId,
}: {
  appointmentV2Details: AppointmentInfo;
  applicationId: string;
}) => {
  const bundle = useChecklistArb();
  const { instantAppointmentURL } = useUrlBuilder();
  const instantAppointmentEligibility = useGetInstantAppointmentEligibility();
  const { sendEvent } = useSendAnalyticEvent();

  const trackClick = useCallback(() => {
    const estimatedWaitTimeSecondsMaximum =
      instantAppointmentEligibility?.status === "ELIGIBLE"
        ? instantAppointmentEligibility.estimatedWaitTimeSecondsMaximum
        : undefined;
    const estimatedWaitTimeSecondsMinimum =
      instantAppointmentEligibility?.status === "ELIGIBLE"
        ? instantAppointmentEligibility.estimatedWaitTimeSecondsMinimum
        : undefined;
    sendEvent("join instant appointment click", {
      estimatedWaitTimeSecondsMaximum,
      estimatedWaitTimeSecondsMinimum,
    });
  }, [sendEvent, instantAppointmentEligibility]);

  if (
    !isEligibleForVirtualInstantAppointment({
      nheDetails: appointmentV2Details,
      applicationId,
      instantAppointmentEligibility,
    }) ||
    // checked in isEligibleForVirtualInstantAppointment already, however, ts can't compile it unless we specific here
    instantAppointmentEligibility?.status !== InstantAppointmentEligibleStatus.ELIGIBLE
  ) {
    return null;
  }

  let instantAppointmentWaitTimeMsg = undefined;

  if (
    instantAppointmentEligibility.estimatedWaitTimeSecondsMaximum !== undefined &&
    instantAppointmentEligibility.estimatedWaitTimeSecondsMinimum !== undefined
  ) {
    const instantAppointmentWaitTimeMin = secondsToMinutes(
      instantAppointmentEligibility.estimatedWaitTimeSecondsMinimum
    );
    const instantAppointmentWaitTimeMax = secondsToMinutes(
      instantAppointmentEligibility.estimatedWaitTimeSecondsMaximum
    );

    if (instantAppointmentWaitTimeMin === instantAppointmentWaitTimeMax || instantAppointmentWaitTimeMin === 0) {
      instantAppointmentWaitTimeMsg = bundle.formatMessage(
        "Checklist-Appointment-Instant-Appointment-Estimated-Wait-Time",
        {
          number: instantAppointmentWaitTimeMax,
        }
      );
    } else {
      instantAppointmentWaitTimeMsg = bundle.formatMessage(
        "Checklist-Appointment-Instant-Appointment-Estimated-Wait-Time-Range",
        {
          number1: instantAppointmentWaitTimeMin,
          number2: instantAppointmentWaitTimeMax,
        }
      );
    }
  }

  return (
    <LinkCard
      backgroundColor={HVH_COLORS.PRIMARY_90}
      style={{ borderRadius: 8, maxWidth: "90%" }}
      data-testid="instant-appointment-banner"
      href={instantAppointmentURL}
      onClick={trackClick}
      target={"_blank"}
    >
      <Col gridGap="1rem">
        <Row>
          <Text color={HVH_COLORS.NEUTRAL_0} fontSize="T200">
            {bundle.formatMessage("Checklist-Appointment-Instant-Appointment-Banner-Title")}
          </Text>
          <IconExternalLink color={HVH_COLORS.NEUTRAL_0} />
        </Row>
        {instantAppointmentWaitTimeMsg && (
          <Text color={HVH_COLORS.PRIMARY_20} fontSize="T100" data-testid="instant-appointment-waittime">
            {instantAppointmentWaitTimeMsg}
          </Text>
        )}
      </Col>
    </LinkCard>
  );
};
