import { useEffect } from "react";
import { useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { useDispatch } from "react-redux";
import { usePostTaskStatusWithBBIDMutation } from "../../reduxStore/api/apiSlice";
import { useGetCandidateData } from "./useGetCandidateData";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";

export const usePostNewHireVideoStatus = () => {
  const { taskStatusWithBBId } = useAppSelector(getConfigData);
  const candidateData = useGetCandidateData();
  const dispatch = useDispatch();
  const [postTaskStatusWithBBId] = usePostTaskStatusWithBBIDMutation();

  useEffect(() => {
    if (taskStatusWithBBId) return;

    if (candidateData) {
      postTaskStatusWithBBId({
        candidateId: candidateData.candidateId ?? "",
        taskType: "NEW_HIRE_VIDEO",
        payload: {
          taskStatus: "Completed",
        },
      })
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.TASK_STATUS_WITH_BB_ID,
              value: data,
            })
          );
          return data ?? console.log("update NEW_HIRE_VIDEO status");
        });
    }
  }, [taskStatusWithBBId, candidateData, postTaskStatusWithBBId, dispatch]);

  return taskStatusWithBBId;
};
