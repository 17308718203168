/* eslint-disable react-hooks/rules-of-hooks */
import { Col } from "@amzn/stencil-react-components/layout";
import { Spinner } from "@amzn/stencil-react-components/spinner";
import { QueryDefinition, QueryStatus } from "@reduxjs/toolkit/dist/query";
import { LazyQueryTrigger } from "@reduxjs/toolkit/dist/query/react/buildHooks";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { FeatFlag, SUPPORTED_COUNTRY } from "../config/appConfigConstants";
import {
  BACKGROUNDCHECK_INFORMATION,
  CREATE_EMPLOYEE_A_TO_Z_ACCOUNT,
  DOC_UPLOADS,
  I_9_DOCUMENT,
  ORDER_YOUR_SAFETY_SHOE,
} from "../config/taskConfigConstants";
import { buildUrl } from "../config/urls";
import { isSalesforceApplication } from "../helpers/url-params";
import { mapAllContigenciesStatuses } from "../helpers/utils";
import { apiSlice, useLazyGetTaskStatusQuery } from "../reduxStore/api/apiSlice";
import { useAppDispatch, useAppSelector } from "../reduxStore/reduxHooks";
import { getConfigData, getFeatureFlag, setConfigData } from "../reduxStore/slices/configSlice";
import * as adobeAnalytics from "../utility/adobe-analytics";
import { isCustomerService, isTaskEnabledByAppConfig } from "../utility/app-config-helper";
import { CandidateData } from "../utility/candidate-data";
import { dispatchOnDev } from "../utility/dev-env-helpers";
import { CONFIG_DATA_KEY, StatusEnum } from "../utility/enums/common";
import { getLocale, getCountryForDomain, getDefaultLocaleAndCountryForCurrentDomain } from "../utility/locale-helper";
import { useAshRum } from "../hooks/useAshRum.hook";
import { AllContingencies } from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { I9Response } from "../utility/types/common";
import { setTaskStatus, TaskKey } from "../reduxStore/slices/taskStatusSlice";

const TaskStatusMap: Record<string, StatusEnum> = {
  ELIGIBLE: StatusEnum.ELIGIBLE,
  IN_PROGRESS: StatusEnum.IN_PROGRESS,
  SUBMITTED: StatusEnum.COMPLETED,
  COMPLETED: StatusEnum.COMPLETED,
  INELIGIBLE: StatusEnum.INELIGIBLE,
  NOT_AVAILABLE_YET: StatusEnum.NOT_AVAILABLE_YET,
  ACTION_REQUIRED: StatusEnum.ACTION_REQUIRED,
  REJECTED: StatusEnum.REJECTED,
};

const NewHireVideo = "NEW_HIRE_VIDEO";

interface WithStatusProps {
  key: string;
  renderComponent: (status?: StatusEnum, taskId?: string) => JSX.Element;
  /**
   * Templated path i.e. 'api/resource1/123/resource2/456'
   */
  statusPath?: string;
  applicationId?: string;
  candidateId?: string;
  hideWhenError?: boolean;
  shouldOverrideStatus?: Record<string, StatusEnum>;
  taskName?: string;
  taskStatusName?: string;
  getTaskStatusByBBId?: boolean;
  enableTask?: boolean;
  bundle?: any;
  onTaskStatusEvent?: (k: string, v: StatusEnum) => void;
  hideOnConfig?: boolean | undefined;
  businessLine?: string;
  defaultStatus?: StatusEnum;
  recordEvent: ReturnType<typeof useAshRum>["recordRumEvent"];
}

interface TasksStatusProps {
  status: StatusEnum;
  badgePhotoSrc: string | undefined;
}

interface GetStatusProps {
  url: string;
  statusPath: string;
  taskName: string | undefined;
  isEligibleToSkipAppt1: boolean;
  getTaskStatusDetail: LazyQueryTrigger<
    QueryDefinition<
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      },
      any,
      string,
      TasksStatusProps,
      "api"
    >
  >;
  dispatch: Function;
  applicationId: string;
  useAllContingencies: boolean;
  allContingenciesResponse: AllContingencies | undefined;
  urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
  recordEvent: ReturnType<typeof useAshRum>["recordRumEvent"];
  caasI9Status: I9Response | "SKIPPED" | undefined;
  caasRTWStatus: I9Response | "SKIPPED" | undefined;
}

// getStatus was exported to be unit tested
// eslint-disable-next-line import/no-unused-modules
export const getStatus = async ({
  url,
  statusPath,
  taskName,
  getTaskStatusDetail,
  isEligibleToSkipAppt1,
  dispatch,
  applicationId,
  useAllContingencies,
  allContingenciesResponse,
  urlParams,
  recordEvent,
  caasI9Status,
  caasRTWStatus,
}: GetStatusProps) => {
  try {
    // Create the default value that will be consumed by later logic, if it's contigencies, read it from the cache instead.
    let isError = false;
    let error;
    let taskStatus: any;
    let fetchStatus = QueryStatus.uninitialized;

    const isMexicoWorkAuthTask = getCountryForDomain() === SUPPORTED_COUNTRY.MX && taskName === I_9_DOCUMENT;
    if (isMexicoWorkAuthTask || !useAllContingencies || isSalesforceApplication(urlParams.applicationId)) {
      const {
        data: _taskStatus,
        isError: _isError,
        error: _error,
        status: _fetchStatus,
      } = await getTaskStatusDetail({ url, useAllContingencies, urlParams });
      isError = _isError;
      error = _error;
      taskStatus = _taskStatus;
      fetchStatus = _fetchStatus;
    } else if (allContingenciesResponse) {
      // Update the value to use from redux instead for I9 Task
      if (caasI9Status && taskName === I_9_DOCUMENT && getCountryForDomain() === SUPPORTED_COUNTRY.US) {
        taskStatus = caasI9Status;
      } else {
        const result = mapAllContigenciesStatuses(url, allContingenciesResponse);
        taskStatus = result;
      }
    }

    if (!taskStatus) {
      if (taskName === CREATE_EMPLOYEE_A_TO_Z_ACCOUNT) {
        recordEvent({
          type: "ash_error_failed_fetch_data_for_AtoZ",
          taskName,
          isError,
          error,
          fetchStatus,
        });

        if (error.status == "FETCH_ERROR" || error.status == "PARSING_ERROR") {
          return StatusEnum.NOT_AVAILABLE_YET;
        }
      }

      throw new Error(`Failed to fetch task status for: ${taskName} and error: ${error.status}`);
    }

    const { status, badgePhotoSrc } = taskStatus;
    if (badgePhotoSrc) {
      dispatch(
        setConfigData({
          key: CONFIG_DATA_KEY.BADGE_PHOTO_URL,
          value: badgePhotoSrc,
        })
      );
    }

    if (taskName === I_9_DOCUMENT) {
      if (caasRTWStatus !== "SKIPPED") {
        return caasRTWStatus?.status;
      } else if (caasI9Status !== "SKIPPED") {
        return caasI9Status?.status;
      }
    }

    return status;
  } catch (e) {
    let message: string | undefined = undefined;
    if (e instanceof Error) {
      message = e.message;
      dispatchOnDev(() => {
        console.error(` Failed to retrieve status with path: ${statusPath} . Message: ${(e as Error).message}`);
      });
    }

    recordEvent({
      type: "ash_error_get_status_request_fail",
      taskName,
      message,
    });

    return StatusEnum.API_ERROR;
  }
};

interface getTaskStatusByBBIdProps {
  getTaskStatusByBBId?: boolean;
  candidateId: string;
  candidateGlobalId: string;
}

export const getTaskStatusURL = ({ candidateGlobalId, candidateId, getTaskStatusByBBId }: getTaskStatusByBBIdProps) => {
  if (getTaskStatusByBBId) {
    return `/api/taskstatus/blackbird/${candidateId}`;
  } else {
    return `/api/taskstatus/${candidateGlobalId}`;
  }
};

type GetTaskStatusProps = {
  taskStatusName: string;
  getTaskStatusByBBId?: boolean;
  candidateData: { candidateId: string; candidateGlobalId: string };
  getTaskStatusDetail: ReturnType<typeof useLazyGetTaskStatusQuery>[0];
  status: StatusEnum;
  useAllContingencies: boolean;
  allContingenciesResponse: AllContingencies | undefined;
  urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
  recordEvent: ReturnType<typeof useAshRum>["recordRumEvent"];
  taskName?: string;
};

let taskId: string;
// getTaskStatus was exported to be unit tested
// eslint-disable-next-line import/no-unused-modules
export const getTaskStatus = async ({
  taskStatusName,
  getTaskStatusByBBId,
  candidateData,
  getTaskStatusDetail,
  status,
  useAllContingencies,
  allContingenciesResponse,
  urlParams,
  taskName,
  recordEvent,
}: GetTaskStatusProps) => {
  try {
    const country = getCountryForDomain();

    const { candidateId, candidateGlobalId } = candidateData;

    if (taskStatusName === DOC_UPLOADS) {
      return status || StatusEnum.ACTION_REQUIRED;
    }

    const url = getTaskStatusURL({ candidateGlobalId, candidateId, getTaskStatusByBBId });

    // Create the default value that will be consumed by later logic, if it's contigencies, read it from the cache instead.
    let taskStatus: any;
    if (!useAllContingencies || isSalesforceApplication(urlParams.applicationId)) {
      const { data: _taskStatus } = await getTaskStatusDetail({ url, useAllContingencies, urlParams });
      taskStatus = _taskStatus;
    } else if (allContingenciesResponse) {
      const result = mapAllContigenciesStatuses(url, allContingenciesResponse);
      taskStatus = result;
    }

    if (taskName === ORDER_YOUR_SAFETY_SHOE && country === SUPPORTED_COUNTRY.CA) {
      return StatusEnum.SKIPPED;
    }

    //Need to check if we are using getTaskStatusByBBId because the old UI does not use TSS to store the status. It might cause issue without it.
    if (taskStatusName === NewHireVideo && getTaskStatusByBBId) {
      if (taskStatus.errorCode == 404) {
        return StatusEnum.NOT_STARTED;
      }
      for (const result of taskStatus) {
        if (result.taskType === NewHireVideo) {
          return result.taskStatus;
        }
      }

      return StatusEnum.NOT_STARTED;
    }

    if (taskStatus.errorCode) {
      if (taskStatus.errorCode != 404) {
        throw Error(
          `Error when requesting task status from ${url}: ${taskStatus.errorCode}, ${taskStatus.errorMessage}`
        );
      }

      recordEvent({
        type: "ash_error_get_task_status",
        issue: "404",
        taskStatusName,
        timestamp: Date.now(),
      });

      return StatusEnum.API_ERROR;
    }

    const taskStatusObject = taskStatus?.find((obj: { taskType: string }) => obj.taskType === taskStatusName);

    if (!taskStatusObject.taskStatus) {
      recordEvent({
        type: "ash_error_get_task_status",
        issue: "missing",
        taskStatusName,
        timestamp: Date.now(),
      });

      return StatusEnum.API_ERROR;
    }

    if (getTaskStatusByBBId) {
      taskId = taskStatusObject.taskId;
    }

    return TaskStatusMap[taskStatusObject.taskStatus];
  } catch (e) {
    recordEvent({
      type: "ash_error_get_task_status",
      issue: "other",
      taskStatusName,
      timestamp: Date.now(),
    });

    if (e instanceof Error) {
      dispatchOnDev(() => {
        console.error(`Failed to retrieve status for task: ${taskStatusName} . Message: ${(e as Error).message}`);
      });
    }

    return StatusEnum.API_ERROR;
  }
};

export const withStatus = ({
  key,
  statusPath,
  hideWhenError,
  renderComponent,
  shouldOverrideStatus,
  taskName,
  taskStatusName,
  getTaskStatusByBBId,
  enableTask,
  bundle,
  onTaskStatusEvent,
  hideOnConfig,
  businessLine,
  defaultStatus,
  recordEvent,
}: WithStatusProps) => {
  const dispatch = useAppDispatch();
  const { caasI9Status, caasRTWStatus } = useAppSelector(getConfigData);

  const { isEligibleToSkipAppt1 } = useAppSelector(getFeatureFlag);

  const { applicationId } = useParams<{ applicationId: string }>();
  const { data: scheduleDetails } = apiSlice.endpoints.getJobDetails.useQueryState({
    applicationId,
    locale: getLocale(),
    isSalesforceApplication: isSalesforceApplication(applicationId),
  });
  const { data: candidateData } = apiSlice.endpoints.getCandidateData.useQueryState({ applicationId });
  const { data: applicationManageData } = apiSlice.endpoints.getApplicationManageData.useQueryState({
    applicationId,
    locale: getLocale(),
    isCustomerService: isCustomerService(applicationId),
  });

  const isAllContingenciesCallEnabledByAppConfig: boolean =
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_BGC, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_AIR_DT, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_BGC, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_DT, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_MEDICAL_CHECK, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId) ||
    isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH, applicationId);

  // CaaS cannot handle applications without schedule ID
  const validStateForCallingAllContingencies: boolean = Boolean(
    isAllContingenciesCallEnabledByAppConfig && !!applicationManageData?.scheduleId
  );

  const locale = getLocale();

  const { data: allContingenciesResponse } = apiSlice.endpoints.getAllContingenciesByApplicationId.useQueryState(
    {
      applicationId,
      candidateId: candidateData?.candidateId || "",
      redirectUrl: encodeURIComponent(window.location.href),
      locale: locale,
    },
    {
      skip: candidateData === undefined,
    }
  );

  const [getTaskStatusDetail] = useLazyGetTaskStatusQuery();

  const loadingText = bundle.getMessage("Checklist-General-LoadingTask");
  const [status, setStatus] = useState<StatusEnum>(StatusEnum.LOADING);

  const redirectUrl = encodeURIComponent(window.location.href);
  // check taskName && task flag status
  let useAllContingencies: boolean;
  if (taskName === I_9_DOCUMENT && validStateForCallingAllContingencies) {
    useAllContingencies =
      getDefaultLocaleAndCountryForCurrentDomain().country === "US"
        ? isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_WORK_AUTH, applicationId)
        : isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_RTW, applicationId);
  }
  if (taskName === BACKGROUNDCHECK_INFORMATION && validStateForCallingAllContingencies) {
    useAllContingencies = isTaskEnabledByAppConfig(FeatFlag.ENABLE_ALL_CONTINGENCIES_BGC, applicationId);
  }

  useEffect(() => {
    if (!statusPath && !taskStatusName) {
      if (defaultStatus) {
        setStatus(defaultStatus);
      }

      return;
    }

    const { candidateId, sfCandidateId, candidateGlobalId } = candidateData as CandidateData;

    if (enableTask && !hideOnConfig) {
      if (statusPath) {
        const url = buildUrl(statusPath, {
          candidateId,
          sfCandidateId,
          candidateGlobalId,
          applicationId,
          locale,
          businessLine,
          redirectUrl,
        });

        if (!getTaskStatusByBBId) {
          getStatus({
            isEligibleToSkipAppt1: !!isEligibleToSkipAppt1,
            url,
            statusPath,
            taskName,
            getTaskStatusDetail,
            dispatch,
            applicationId,
            useAllContingencies,
            allContingenciesResponse,
            urlParams: { applicationId, candidateId, redirectUrl },
            recordEvent,
            caasI9Status: caasI9Status,
            caasRTWStatus: caasRTWStatus,
          }).then(function (status) {
            setStatus(status);
          });
        }
      }

      taskStatusName &&
        candidateData &&
        getTaskStatus({
          taskStatusName,
          getTaskStatusByBBId,
          candidateData,
          getTaskStatusDetail,
          status,
          taskName,
          useAllContingencies,
          allContingenciesResponse,
          urlParams: { applicationId, candidateId, redirectUrl },
          recordEvent,
        }).then(function (status) {
          setStatus(status);
        });
    }
  }, [
    statusPath,
    enableTask,
    hideOnConfig,
    businessLine,
    candidateData,
    isEligibleToSkipAppt1,
    taskStatusName,
    defaultStatus,
    applicationId,
    getTaskStatusByBBId,
    getTaskStatusDetail,
    status,
    redirectUrl,
    locale,
    taskName,
    dispatch,
    caasI9Status,
  ]);

  // This populates the status map only used for new UI
  useEffect(() => {
    // Do not count task in counter if task is loading
    if (status == StatusEnum.LOADING) {
      return;
    }

    if (onTaskStatusEvent && !hideOnConfig && enableTask) {
      onTaskStatusEvent(
        key,
        shouldOverrideStatus && shouldOverrideStatus[status] ? shouldOverrideStatus[status] : status
      );

      dispatch(
        setTaskStatus({
          key: taskName as TaskKey,
          value: shouldOverrideStatus && shouldOverrideStatus[status] ? shouldOverrideStatus[status] : status,
        })
      );
    }
  }, [status, hideOnConfig]);

  if (!enableTask) {
    return null;
  }

  if (!statusPath && !taskStatusName) {
    return renderComponent(undefined);
  }

  const spinner = <Spinner key={key} loadingText={loadingText} showText />;
  if (status === StatusEnum.LOADING && !hideOnConfig) {
    return (
      <Col justifyContent={"center"} padding={"1rem"}>
        {spinner}
      </Col>
    );
  }

  if (hideWhenError && taskName === CREATE_EMPLOYEE_A_TO_Z_ACCOUNT && status === StatusEnum.API_ERROR) {
    recordEvent({
      type: "ash_error_a2z",
      applicationManageData,
      scheduleDetails,
      timestamp: Date.now(),
    });

    adobeAnalytics.addEventMetric(
      window,
      applicationId as string,
      "AtoZ task unable to load",
      candidateData,
      applicationManageData,
      scheduleDetails,
      {
        error: { errorMessage: "Getting 'N/A' as status", errorCode: status },
      }
    );
    return null;
  }

  // New ui task rendering logic
  if (hideOnConfig) {
    return null;
  }

  if (getTaskStatusByBBId) {
    return renderComponent(status, taskId);
  }
  return renderComponent(status);
};

const resolveI9TaskStatus = (status: StatusEnum) => {
  const country = getCountryForDomain();

  if (country === SUPPORTED_COUNTRY.MX && !status) {
    // https://t.corp.amazon.com/P100507740 - when status is missing, return NOT_STARTED
    return StatusEnum.NOT_STARTED;
  }

  return status || StatusEnum.NOT_AVAILABLE_YET;
};
