export enum HVH_COLORS {
  NEUTRAL_0 = "#ffffff",
  NEUTRAL_05 = "#F1F4F7",
  NEUTRAL_10 = "#e8eaee",
  NEUTRAL_20 = "#d1d5da",
  NEUTRAL_70 = "#56616c",
  NEUTRAL_90 = "#232F3E",
  NEUTRAL_100 = "#161e2d",
  RED_10 = "#fde1df",
  RED_70 = "#bb2929",
  PRIMARY_05 = "#ebf5fd",
  PRIMARY_20 = "#AFD7F7",
  PRIMARY_70 = "#1768c9",
  PRIMARY_80 = "#1659a9",
  PRIMARY_90 = "#123B74",
  GREEN_10 = "#d7f3ed",
  GREEN_60 = "#008574",
  GREEN_70 = "#23756b",
  YELLOW_10 = "#fff3cc",
  YELLOW_60 = "#ca8700",
}
