import { Stage } from "../helpers/get-settings";

export type QueryParam = string | string[] | undefined;

export interface UrlProps {
  stage?: Stage;
  requisitionId?: QueryParam;
  applicationId?: QueryParam;
  candidateId?: QueryParam;
  candidateGlobalId?: string;
  sfCandidateId?: QueryParam;
  redirectUrl?: QueryParam;
  scheduleId?: QueryParam;
  cssPage?: QueryParam;
  locale?: QueryParam;
  requisitionCategory?: QueryParam;
  taskType?: QueryParam;
  businessLine?: QueryParam;
  applicationType?: QueryParam;
  hostName?: QueryParam;
}

// ---------------- API ---------------------------
export const METRIC_API_URL = "/api/metric/";
export const VIDEO_RECORD_API_URL = "/api/video-record/:applicationId";
export const VIDEO_RECORD_NON_ASH_API_URL = "/api/video-record/non-ash/:requisitionId/:candidateId";
export const CAAS_API_URL = "/api/caas/:candidateId?redirectUrl=:redirectUrl";

// ---------------- Internal pages ----------------
export const CHECKLIST_URL = "/checklist/:requisitionId/:applicationId";
export const CHECKLIST_URL_CID = "/checklist/:requisitionId/:applicationId/:candidateId";
export const NEW_HIRE_VIDEO_URL = "/checklist/tasks/new-hire-video/:requisitionId/:applicationId";
export const TAKE_BADGE_PHOTO_START_URL = "/checklist/tasks/badge/:requisitionId/:applicationId/start";
export const TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL =
  "/checklist/tasks/badge/:requisitionId/:applicationId/camera/gallery";
export const TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL =
  "/checklist/tasks/badge/:requisitionId/:applicationId/camera/requirements";
export const TAKE_BADGE_PHOTO_CAMERA_URL = "/checklist/tasks/badge/:requisitionId/:applicationId/camera";
export const COMPLETE_PHO_URL = "/checklist/tasks/pho/:requisitionId/:applicationId"; //Todo update if needed
export const TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL =
  "/checklist/tasks/badge/:requisitionId/:applicationId/upload/requirements";
export const TAKE_BADGE_PHOTO_UPLOAD_URL = "/checklist/tasks/badge/:requisitionId/:applicationId/upload";
export const TAKE_BADGE_PHOTO_CONFIRM_URL = "/checklist/tasks/badge/:requisitionId/:applicationId/confirm";
export const TAKE_BADGE_PHOTO_RESULT_URL = "/checklist/tasks/badge/:requisitionId/:applicationId/result";
export const OLD_DOCUMENT_UPLOAD_URL = "/checklist/tasks/document/:requisitionId/:applicationId/:documentType/:isEdit";

// Ensure you update this when adding new paths, we need it for RUM path detection
export const ALL_URLS = [
  CHECKLIST_URL,
  CHECKLIST_URL_CID,
  NEW_HIRE_VIDEO_URL,
  TAKE_BADGE_PHOTO_START_URL,
  TAKE_BADGE_PHOTO_CAMERA_GALLERY_URL,
  TAKE_BADGE_PHOTO_CAMERA_REQUIREMENTS_URL,
  TAKE_BADGE_PHOTO_CAMERA_URL,
  TAKE_BADGE_PHOTO_UPLOAD_REQUIREMENTS_URL,
  TAKE_BADGE_PHOTO_UPLOAD_URL,
  TAKE_BADGE_PHOTO_CONFIRM_URL,
  TAKE_BADGE_PHOTO_RESULT_URL,
  OLD_DOCUMENT_UPLOAD_URL,
] as const;

// ---------------- More API ---------------------------
export const I9_STATUS_ENDPOINT = "/api/status/i9/candidate/:candidateId";
export const BACKGROUND_CHECK_STATUS_ENDPOINT = "/api/status/background-check/candidate/:candidateId/:applicationId";
export const RIGHT_TO_WORK_STATUS_ENDPOINT = "/api/status/right-to-work/candidate/:candidateId";
export const BADGE_PHOTO_STATUS_ENDPOINT = "/api/status/badge-photo/candidate/:candidateId";
export const NEW_HIRE_VIDEO_STATUS_ENDPOINT = "/api/status/video/application/:applicationId";
export const ONBASE_DOCUMENT_STATUS_ENDPOINT = "/api/status/document-status/:candidateGlobalId/:applicationId/:locale";
export const ATOZ_STATUS_ENDPOINT = "/api/status/atoz-status/:candidateGlobalId/:applicationId/:locale";
export const RTW_APPOINTMENT_STATUS_ENDPOINT = "/api/status/nhe-status/application/:applicationId/RIGHT_TO_WORK";
export const MANDATORY_DOC_UPLOAD_STATUS_ENDPOINT = "/api/status/document-upload/:candidateId/mandatoryDocuments/MX";
export const WORK_AUTH_DOC_UPLOAD_STATUS_ENDPOINT =
  "/api/status/document-upload/:candidateId/workAuthorizationDocuments/MX";

// ---------------- External links ----------------
export const USCIS_URL = "https://www.uscis.gov/i-9-central/form-i-9-acceptable-documents";
export const USCIS_URL_ES = "https://www.uscis.gov/es/central-i-9/documentos-aceptables-del-formulario-i-9";
export const I9_ADVANTAGE_URL = "https://hr.i9advantage.com/section1/email/confirm_request.aspx?id=125";
export const MX_ACCEPTABLE_DOCUMENTS_URL_EN =
  "https://walpub.s3.us-west-2.amazonaws.com/EmployeeGuides/AMER-LATAM/Mexico/English+Version";
export const MX_ACCEPTABLE_DOCUMENTS_URL_ES =
  "https://walpub.s3.us-west-2.amazonaws.com/EmployeeGuides/AMER-LATAM/Mexico/Spanish+Version.pdf";

export const ZAPPOS_URL = "https://www.zappos.com/newhire";
export const ZAPPOS_FAQ_URL = "https://www.zappos.com/c/zaw-amazon-faq";

export const CSA_URL =
  "https://www.canada.ca/en/employment-social-development/services/health-safety/reports/feet.html";

export const CSA_URL_FR =
  "https://www.canada.ca/fr/emploi-developpement-social/services/sante-securite/rapports/pieds.html";

export const WORK_AUTHORITY_STORE_URL = "https://workauthority.ca/pages/sca-store-locator";
export const WORK_AUTHORITY_STORE_URL_FR = "https://expertautravail.ca/pages/sca-store-locator";

export const CSS_PAGE_URL = "{hostName}/selfservice/schedule/current-schedule/{applicationId}/{requisitionId}";

export const CSS_NHE_PAGE_URL = "{hostName}/selfservice/appointment/new-hire-event/{applicationId}/{requisitionId}";

export const SCHEDULE_RIGHT_TO_WORK_APPOINTMENT_URL =
  "{hostName}/selfservice/appointment/right-to-work/{applicationId}/{requisitionId}";

export const CSS_DT_RESCHEDULE_URL = "{hostName}/selfservice/appointment/drug-test/{applicationId}/{requisitionId}";

export const CSS_DT_RETAKE_SCHEDULE_URL =
  "{hostName}/selfservice/appointment/drug-test-retake/{applicationId}/{requisitionId}";

export const CSS_MY_JOBS_URL = "{hostName}/app#/myApplications";

export const BB_BGC_INFORMATION_COLLECTION_URL =
  "{hostName}/application/bgc/?applicationId={applicationId}&jobId={requisitionId}";

const ADOBE_DATA_LAYER_URL_NON_PROD =
  "https://assets.adobedtm.com/launch-EN2c38d445ba884f1ca9901284481b2c7b-staging.min.js";
const ADOBE_DATA_LAYER_URL_PROD = "https://assets.adobedtm.com/launch-EN6bc43e7eee5146beaa43d868366aabe6.min.js";

export const ADOBE_DATA_LAYER_URL: Record<Stage, string> = {
  local: ADOBE_DATA_LAYER_URL_NON_PROD,
  alpha: ADOBE_DATA_LAYER_URL_NON_PROD,
  "alpha-mx": ADOBE_DATA_LAYER_URL_NON_PROD,
  beta: ADOBE_DATA_LAYER_URL_NON_PROD,
  "beta-mx": ADOBE_DATA_LAYER_URL_NON_PROD,
  gamma: ADOBE_DATA_LAYER_URL_NON_PROD,
  "gamma-mx": ADOBE_DATA_LAYER_URL_NON_PROD,
  "gamma-uk": ADOBE_DATA_LAYER_URL_NON_PROD,
  "gamma-in": ADOBE_DATA_LAYER_URL_NON_PROD,
  "gamma-fe": ADOBE_DATA_LAYER_URL_NON_PROD,
  "gamma-za": ADOBE_DATA_LAYER_URL_NON_PROD,
  preprod: ADOBE_DATA_LAYER_URL_NON_PROD,
  "preprod-mx": ADOBE_DATA_LAYER_URL_NON_PROD,
  "preprod-uk": ADOBE_DATA_LAYER_URL_NON_PROD,
  "preprod-in": ADOBE_DATA_LAYER_URL_NON_PROD,
  "preprod-fe": ADOBE_DATA_LAYER_URL_NON_PROD,
  "preprod-za": ADOBE_DATA_LAYER_URL_NON_PROD,
  prod: ADOBE_DATA_LAYER_URL_PROD,
  "prod-mx": ADOBE_DATA_LAYER_URL_PROD,
  "prod-uk": ADOBE_DATA_LAYER_URL_PROD,
  "prod-in": ADOBE_DATA_LAYER_URL_PROD,
  "prod-fe": ADOBE_DATA_LAYER_URL_PROD,
  "prod-za": ADOBE_DATA_LAYER_URL_PROD,
};

const KNOWN_PARAMETERS = [
  "requisitionId",
  "applicationId",
  "candidateId",
  "sfCandidateId",
  "redirectUrl",
  "scheduleId",
  "cssPage",
  "locale",
  "requisitionCategory",
  "taskType",
  "businessLine",
  "candidateGlobalId",
  "applicationType",
  "hostName",
];

export const buildUrl = (url: string | Record<Stage, string>, { stage, ...params }: UrlProps): string => {
  const href = typeof url === "string" ? url : url[stage || ("local" as Stage)];
  return KNOWN_PARAMETERS.reduce((acc, paramName) => {
    const value = (params as any)[paramName];
    if (!value) {
      return acc;
    }
    return acc.replace(`:${paramName}`, value).replace(`{${paramName}}`, value);
  }, href);
};
