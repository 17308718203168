import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { ContingencyResponse } from "../../components/screening-results/screeningResults";
import {
  ApplicationManageData,
  AtoZStatus,
  DocumentStatus,
  JobDetailsModel,
  RightToWorkResponse,
  SdaInfo,
  VideoData,
} from "../../utility/application-data";
import { CandidateData } from "../../utility/candidate-data";
import { AppConfigResponse, I9Response, ValidCandidateContingenciesResponse } from "../../utility/types/common";
import { AttachmentMetaData } from "../../routes/tasks/document/entry";
import { UploadDetails } from "../../routes/tasks/document/models";
import { IdDataParams, PresignedURLResponse } from "../../routes/tasks/document/requests/requests";
import { getDefaultLocaleAndCountryForCurrentDomain } from "../../utility/locale-helper";
import {
  AllContingencies,
  AppointmentInfo,
  NHEAppointmentApplicationTypeEnum,
  TaskType,
  TaskStatusModel,
  WidgetDetails,
  InstantEligibility,
} from "@amzn/hvh-simple-hire-checklist-internal-api-common";
import { UnsoundEither } from "../../utility/generic-types";
import { isSalesforceApplication } from "../../helpers/url-params";
import { JobPreviewVideoDetailsResponse } from "../../hooks/apis/useGetJobPreviewVideoData";

interface getBgcEndpoint {
  applicationId: string;
  candidateId: string;
  isAirJob: boolean;
  isCustomerService: boolean;
}

export const getBgcEndpoint = ({ applicationId, candidateId, isAirJob, isCustomerService }: getBgcEndpoint) => {
  if (isCustomerService) {
    return `/api/salesforce/background-check/candidate/${candidateId}/${applicationId}`;
  } else if (isAirJob) {
    return `/api/status/background-check/air/candidate/${candidateId}/${applicationId}`;
  }
  return `/api/status/background-check/candidate/${candidateId}/${applicationId}`;
};

export const apiSlice = createApi({
  reducerPath: "api",
  baseQuery: fetchBaseQuery({
    /**
     * The credentials option is passed to fetch, and using "omit" disables sending cookes with requests. This is important, because
     * cookes are sent in the "Cookie" HTTP header which is counted agains the cloudfront size limit of 10248 bytes. Sometimes enough
     * other sites have set applicable cookes that the cookie header alone is over 9000 bytes, resulting in a 413 response from cloudfront
     * when running the health check. Thic can cause a redirect loop from auth site since the user is technically logged in, so they redirect
     * to ASH, but then the ASH health check fails and ASH sends the user to auth... Since we can't control the individual cookies sent with
     * each request, we have limited options to deal with the excess cookes. To resolve the issue, we just don't send cookes at all since we
     * can use custom HTTP headers for any special data, for example X-ASH-Country to relay the career site domain's associated country.
     *
     * TLDR; Too many other things set cookies on the career site domain, and it causes a 413 error when the headers get too big for cloudfront
     */
    credentials: "omit", // We use X-ASH-Authorization / X-ASH-Session instead of cookies for auth
    baseUrl: process.env.NODE_ENV === "development" ? "http://localhost:9000/checklist/" : "/checklist/",
    prepareHeaders: (headers) => {
      const countryCode = getDefaultLocaleAndCountryForCurrentDomain().country;
      // This will request that a response will not be saved in any cache, an existing cached value could still be returned by a cache (such as browser built-in cache)
      // Ref. https://datatracker.ietf.org/doc/html/rfc7234#section-5.2.1.5
      // The checklist server should already set this as a response header, so this is not a behavior change
      // Ref. https://code.amazon.com/packages/HvhSimpleHireChecklistServer/blobs/3bbeea23cdd25d39ab3784da92a050c8e4875205/--/src/express-server.ts#L41
      headers.set("Cache-Control", "no-store");
      headers.set("X-ASH-Country", countryCode);
      const token = window.localStorage.getItem("accessToken");
      const sessionToken = window.localStorage.getItem("sessionToken") as string;
      const startFromA2Z = window.sessionStorage.getItem("startFromAtoZ");

      if (token) {
        headers.set("X-ASH-Authorization", token);
        headers.set("X-ASH-Session", sessionToken);
        headers.set("X-Frame-Options", "DENY");
      }

      if (startFromA2Z) {
        headers.set("startfroma2z", startFromA2Z);
      }

      return headers;
    },
  }),
  tagTypes: ["DocStatusCards"],
  endpoints: (builder) => ({
    // ============  GET Requests ==============//
    getAppConfig: builder.query<AppConfigResponse, void>({
      query: () => `api/getAppConfig/getASHConfig`,
    }),

    // Used on "Complete your Right to Work Documentation" tasks when it is PendingF2FCheck
    getCaasRTWStatus: builder.query<RightToWorkResponse, string>({
      query: (candidateId) => `/api/status/right-to-work/candidate/${candidateId}`,
    }),

    getCaasInfo: builder.query<I9Response, { candidateId: string; redirectUrl: string }>({
      query: ({ candidateId, redirectUrl }) => `/api/caas/${candidateId}?redirectUrl=${redirectUrl}`,
    }),

    getCassValidCandidateContingenciesStatus: builder.query<
      ValidCandidateContingenciesResponse,
      { applicationId: string }
    >({
      query: ({ applicationId }) => {
        return `/api/caas-status/${applicationId}`;
      },
    }),

    getAllContingenciesByApplicationId: builder.query<
      AllContingencies,
      { applicationId: string; candidateId: string; redirectUrl: string; locale: string }
    >({
      query: ({ applicationId, candidateId, redirectUrl, locale }) =>
        `/api/status/allContingencies/${applicationId}/${candidateId}/${redirectUrl}/${locale}`,
    }),

    getAppointmentV2: builder.query<
      UnsoundEither<AppointmentInfo, { errorStatusCode: number; errorCode: string }>,
      { applicationId: string; applicationType: NHEAppointmentApplicationTypeEnum; locale: string }
    >({
      query: ({ applicationId, applicationType, locale }) =>
        `/api/nhe/appointment-v2/${applicationId}/${applicationType}/${locale}`,
    }),

    getInstantAppointmentEligibility: builder.query<InstantEligibility, { applicationId: string; locale: string }>({
      query: ({ applicationId, locale }) => `/api/connect/instant-eligibility/${applicationId}/${locale}`,
    }),

    getRvpWidgetDetails: builder.query<WidgetDetails, { applicationId: string; locale: string }>({
      query: ({ applicationId, locale }) => ({
        url: `/api/connect/widget-details/${applicationId}/NEW_HIRE_EVENT/${locale}`,
        params: { rvp: "true" },
      }),
    }),

    getJobPreviewVideoDetails: builder.query<
      JobPreviewVideoDetailsResponse,
      { applicationId: string; taskType: string }
    >({
      query: ({ applicationId, taskType }) => `/api/taskstatus/${applicationId}/taskType/${taskType}`,
    }),

    getJobDetails: builder.query<
      JobDetailsModel,
      { applicationId: string; locale: string; isSalesforceApplication: boolean }
    >({
      async queryFn({ applicationId, locale, isSalesforceApplication }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (applicationId) {
          const jobDetails = await fetchWithBasicQuery(
            isSalesforceApplication
              ? `api/salesforce/jobdetails/${applicationId}/${locale}`
              : `api/jobcontainer/schedule/${applicationId}/${locale}?includeStartDate=true`
          );
          return jobDetails.error ? { error: jobDetails.error } : { data: jobDetails.data as JobDetailsModel };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called getJobDetails api without applicationId",
            },
          };
        }
      },
    }),

    getStartDateInfo: builder.query<SdaInfo, { applicationId: string; locale: string }>({
      query: ({ applicationId, locale }) => `/api/sda/${applicationId}/${locale}`,
    }),

    getApplicationManageData: builder.query<
      ApplicationManageData,
      { applicationId: string; locale: string; isCustomerService: boolean }
    >({
      async queryFn({ applicationId, locale, isCustomerService }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (applicationId) {
          const applicationManageData = await fetchWithBasicQuery(
            `/api/${!isCustomerService ? "application" : "salesforce"}/application-manage-data/${applicationId}${
              !isCustomerService ? "/" + locale : ""
            }`
          );
          return applicationManageData.error
            ? { error: applicationManageData.error }
            : { data: applicationManageData.data as ApplicationManageData };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called getApplicationManageData api without applicationId",
            },
          };
        }
      },
    }),

    getDocumentStatusInfo: builder.query<
      DocumentStatus,
      { candidateGlobalId: string | undefined; applicationId: string; locale: string; edmLoginEnabled?: boolean }
    >({
      async queryFn(
        { candidateGlobalId, applicationId, locale, edmLoginEnabled },
        _queryApi,
        _extraOptions,
        fetchWithBasicQuery
      ) {
        if (candidateGlobalId) {
          const docStatus = await fetchWithBasicQuery(
            `/api/document-status/${candidateGlobalId}/${applicationId}/Salesforce/False/${locale}?edmLoginEnabled=${edmLoginEnabled}`
          );
          return docStatus.error ? { error: docStatus.error } : { data: docStatus.data as DocumentStatus };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called getDocumentStatusInfo without candidateGlobalId",
            },
          };
        }
      },
    }),

    getBgcStatus: builder.query<
      ContingencyResponse,
      { candidateId: string | undefined; applicationId: string; isCustomerService: boolean; businessLine?: string }
    >({
      async queryFn(
        { candidateId, applicationId, businessLine, isCustomerService },
        _queryApi,
        _extraOptions,
        fetchWithBasicQuery
      ) {
        if (candidateId) {
          const bgcStatusData = await fetchWithBasicQuery(
            getBgcEndpoint({
              applicationId: applicationId,
              candidateId: candidateId,
              isCustomerService: isCustomerService,
              isAirJob: businessLine === "AIR",
            })
          );
          return bgcStatusData.error
            ? { error: bgcStatusData.error }
            : { data: bgcStatusData.data as ContingencyResponse };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called bgc api without candidateId",
            },
          };
        }
      },
    }),

    getDtStatus: builder.query<ContingencyResponse, { candidateGlobalId: string | undefined; businessLine?: string }>({
      async queryFn({ candidateGlobalId, businessLine }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (candidateGlobalId) {
          const drugtestStatusData = await fetchWithBasicQuery(
            `/api/status/drug-test/candidateGlobalId/${candidateGlobalId}/businessLine/${businessLine}`
          );
          return drugtestStatusData.error
            ? { error: drugtestStatusData.error }
            : { data: drugtestStatusData.data as ContingencyResponse };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called drugtest api without candidateGlobalId",
            },
          };
        }
      },
    }),

    getDtStatusWithSfCandidateId: builder.query<
      ContingencyResponse,
      { sfCandidateId: string | undefined; businessLine?: string }
    >({
      async queryFn({ sfCandidateId, businessLine }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (sfCandidateId) {
          const drugtestStatusData = await fetchWithBasicQuery(
            `/api/status/drug-test/candidate/${sfCandidateId}/businessLine/${businessLine}`
          );
          return drugtestStatusData.error
            ? { error: drugtestStatusData.error }
            : { data: drugtestStatusData.data as ContingencyResponse };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called drugtest api without sfCandidateId",
            },
          };
        }
      },
    }),

    getCaasDtStatus: builder.query<ContingencyResponse, { applicationId: string; isAlumni: boolean | undefined }>({
      async queryFn({ applicationId, isAlumni }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (applicationId) {
          const drugtestStatusData = await fetchWithBasicQuery(
            `/api/status/drug-test/application/${applicationId}?alumni=${isAlumni}`
          );
          return drugtestStatusData.error
            ? { error: drugtestStatusData.error }
            : { data: drugtestStatusData.data as ContingencyResponse };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called drugtest api without applicationId",
            },
          };
        }
      },
    }),

    getMcStatus: builder.query<ContingencyResponse, { candidateId: string | undefined }>({
      async queryFn({ candidateId }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (candidateId) {
          const medicalCheckStatusData = await fetchWithBasicQuery(
            `/api/status/medical-check/candidate/${candidateId}`
          );
          return medicalCheckStatusData.error
            ? { error: medicalCheckStatusData.error }
            : { data: medicalCheckStatusData.data as ContingencyResponse };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called medical check api without candidateId",
            },
          };
        }
      },
    }),

    getCandidateAtoZStatus: builder.query<
      AtoZStatus,
      { candidateGlobalId: string | undefined; applicationId: string; locale: string; returnResetPasswordUrl: string }
    >({
      async queryFn(
        { candidateGlobalId, applicationId, locale, returnResetPasswordUrl },
        _queryApi,
        _extraOptions,
        fetchWithBasicQuery
      ) {
        if (candidateGlobalId) {
          const aToZData = await fetchWithBasicQuery(
            `/api/atoz/${candidateGlobalId}/${applicationId}/${locale}/${returnResetPasswordUrl}`
          );
          return aToZData.error ? { error: aToZData.error } : { data: aToZData.data as AtoZStatus };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called aToZData without candidateGlobalId",
            },
          };
        }
      },
    }),

    getCandidateData: builder.query<CandidateData, { applicationId: string }>({
      async queryFn({ applicationId }, _queryApi, _extraOptions, fetchWithBasicQuery) {
        if (applicationId) {
          const candidateData = await fetchWithBasicQuery(`api/cds/candidate/${applicationId}`);
          return candidateData.error ? { error: candidateData.error } : { data: candidateData.data as CandidateData };
        } else {
          return {
            error: {
              status: 400,
              data: "Should not called getCandidateData api without applicationId",
            },
          };
        }
      },
    }),

    getRTWStatus: builder.query<
      any,
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      }
    >({
      query: ({ url, useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        return url;
      },
      transformResponse: (response: AllContingencies, _, { useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return response.RightToWork;
        }
        return response;
      },
    }),

    getBGCheckStatus: builder.query<
      any,
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      }
    >({
      query: ({ url, useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        return url;
      },
      transformResponse: (response: AllContingencies, _, { useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return response.BGC;
        }
        return response;
      },
    }),

    getCaasStatus: builder.query<
      any,
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      }
    >({
      query: ({ url, useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        return url;
      },
      transformResponse: (response: AllContingencies, _, { useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return response.WorkAuthorization;
        }
        return response;
      },
    }),

    getI9Status: builder.query<
      any,
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      }
    >({
      query: ({ url, useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        return url;
      },
      transformResponse: (response: AllContingencies, _, { useAllContingencies, urlParams }) => {
        if (useAllContingencies && !isSalesforceApplication(urlParams.applicationId)) {
          return response.WorkAuthorization;
        }
        return response;
      },
    }),

    // depricated
    getTaskStatus: builder.query<
      any,
      {
        url: string;
        useAllContingencies: boolean;
        urlParams: Record<"applicationId" | "candidateId" | "redirectUrl", string>;
      }
    >({
      query: ({ url, useAllContingencies, urlParams }) => {
        if (!useAllContingencies || isSalesforceApplication(urlParams.applicationId)) {
          return url;
        }
        if (url.startsWith("/api/status/right-to-work/candidate/")) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        if (url.startsWith("/api/status/background-check/candidate/")) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        if (url.startsWith("/api/caas/")) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }
        if (url.startsWith("/api/status/i9/candidate/")) {
          return `/api/status/allContingencies/${urlParams.applicationId}/${urlParams.candidateId}/${urlParams.redirectUrl}`;
        }

        return url;
      },
      transformResponse: (response, meta, { url, useAllContingencies, urlParams }) => {
        if (!useAllContingencies || isSalesforceApplication(urlParams.applicationId)) {
          return response;
        }
        const allContingenciesResponse = response as AllContingencies;
        if (url.startsWith("/api/status/right-to-work/candidate/")) {
          return allContingenciesResponse.RightToWork;
        }
        if (url.startsWith("/api/status/background-check/candidate/")) {
          return allContingenciesResponse.BGC;
        }
        if (url.startsWith(`/api/caas/`)) {
          return allContingenciesResponse.WorkAuthorization;
        }
        if (url.startsWith(`/api/status/i9/candidate/`)) {
          return allContingenciesResponse.WorkAuthorization;
        }
        return response;
      },
    }),

    getOldDocUploadValidator: builder.query<AttachmentMetaData[], { candidateId: string }>({
      query: ({ candidateId }) => `/api/document-upload/${candidateId}`,
    }),

    getCandidateHealth: builder.query<any, null>({
      query: () => `/api/health`,
    }),

    getVideoUrl: builder.query<VideoData, { applicationId: string; locale: string; requisitionCategory: string }>({
      query: ({ applicationId, locale, requisitionCategory }) =>
        `/api/video/${applicationId}/${locale}/${requisitionCategory ? requisitionCategory : ":requisitionCategory"}`,
    }),

    getCsrfToken: builder.query<{ ["anti-csrftoken-a2z"]: string }, null>({
      query: () => `/api/csrf`,
    }),

    // ============  Tasks status Requests ==============//
    postTaskStatusWithBBID: builder.mutation<
      TaskStatusModel,
      { candidateId: string; taskType: TaskType; payload: { taskStatus: string } }
    >({
      query: ({ candidateId, taskType, payload }) => ({
        url: `/api/taskstatus/blackbird/${candidateId}/taskType/${taskType}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }),
    }),

    // ============  Zappos Magic Link retrieval Requests ==============//
    getZapposMagicLink: builder.query<any, { applicationId: string; taskId: string }>({
      query: ({ applicationId, taskId }) => `/api/zappos/magicLink/${applicationId}/${taskId}`,
    }),

    // ============  POST Requests ==============//
    getPresignedURLToCDS: builder.mutation<
      PresignedURLResponse,
      { candidateId: string; applicationId: string; uploadDetails: UploadDetails }
    >({
      query: ({ candidateId, applicationId, uploadDetails }) => ({
        url: `/api/cds/documentUpload/${candidateId}/${applicationId}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(uploadDetails),
      }),
    }),

    updateJobPreviewVideoDetails: builder.mutation<any, { applicationId: string; taskType: string; payload: any }>({
      query: ({ applicationId, taskType, payload }) => ({
        url: `/api/taskstatus/${applicationId}/taskType/${taskType}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }),
    }),

    updateCDSDocumentUploadMetadata: builder.mutation<
      any,
      { candidateId: string; applicationId: string; uploadDetails: UploadDetails }
    >({
      query: ({ candidateId, applicationId, uploadDetails }) => ({
        url: `/api/cds/documentUpload/${candidateId}/${applicationId}`,
        method: "PATCH",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(uploadDetails),
      }),
    }),

    metricsSenderCreateOnFlush: builder.mutation<any, { metrics: any }>({
      query: ({ metrics }) => ({
        url: `/api/metric/`,
        method: "POST",
        body: JSON.stringify({
          metrics,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      }),
    }),

    submitDocToCDSFromPresignedURL: builder.mutation<
      any,
      {
        presignedUrl: string;
        headers: Headers;
        body: "" | Buffer | undefined;
      }
    >({
      query: ({ presignedUrl, headers, body }) => ({
        url: presignedUrl,
        method: "PUT",
        headers: headers,
        body: body,
      }),
    }),

    updateCandidateDocumentIdToCDS: builder.mutation<
      any,
      {
        candidateId: string;
        idData: IdDataParams;
      }
    >({
      query: ({ candidateId, idData }) => ({
        url: `/api/cds/candidate/${candidateId}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(idData),
      }),
    }),

    testCsrf: builder.mutation<any, { headers: any }>({
      query: ({ headers }) => ({
        url: `/api/test/csrf`,
        method: "POST",
        body: {},
        headers: headers,
      }),
    }),

    withDrawApplication: builder.mutation<
      { statusCode: number; errorMessage?: string },
      { applicationId: string; withdrawReason: string }
    >({
      query: ({ applicationId, withdrawReason }) => ({
        url: `/api/withdraw-application/${applicationId}`,
        method: "POST",
        body: JSON.stringify({ withDrawReason: withdrawReason }),
      }),
    }),

    sendMetricVideoRecordAsh: builder.mutation<string, { applicationId: string; payload: string }>({
      query: ({ applicationId, payload }) => ({
        url: `/api/video-record/${applicationId}`,
        method: "POST",
        body: payload,
        keepalive: true, // need this property to track video watch metrics
      }),
    }),

    sendMetricVideoRecordNonAsh: builder.mutation<string, { requisitionId: string; payload: string }>({
      query: ({ requisitionId, payload }) => ({
        url: `/api/video-record/non-ash/${requisitionId}`,
        method: "POST",
        body: payload,
        keepalive: true, // need this property to track video watch metrics
      }),
    }),

    submitBadgePhoto: builder.mutation<
      any,
      {
        candidateId: string;
        payload: {
          captureSource: string;
          badgePhotoContents: string | undefined;
        };
      }
    >({
      query: ({ candidateId, payload }) => ({
        url: `/api/badgephotos/${candidateId}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify(payload),
      }),
    }),
    // Trigger PUT call to Caas when the status is "Caas Status === WaitingForASHToCreateOrder"
    updateCaasI9: builder.mutation<I9Response, { candidateId: string; redirectURL: string }>({
      query: ({ candidateId, redirectURL }) => ({
        url: `/api/caas/putcaas/${candidateId}/${redirectURL}`,
        method: "POST",
      }),
    }),
  }),
});

// ESLint plugin is kind of dumb and can't recognize these are imports in other files
// eslint-disable-next-line import/no-unused-modules
export const {
  useGetAppConfigQuery,
  useGetCaasRTWStatusQuery,
  useGetAllContingenciesByApplicationIdQuery,
  useLazyGetAllContingenciesByApplicationIdQuery,
  useUpdateCaasI9Mutation,
  useLazyGetCaasRTWStatusQuery,
  useGetAppointmentV2Query,
  useLazyGetAppointmentV2Query,
  useGetInstantAppointmentEligibilityQuery,
  useLazyGetInstantAppointmentEligibilityQuery,
  useLazyGetRvpWidgetDetailsQuery,
  useGetJobDetailsQuery,
  useLazyGetJobDetailsQuery,
  useGetVideoUrlQuery,
  useLazyGetVideoUrlQuery,
  useGetPresignedURLToCDSMutation,
  useSubmitDocToCDSFromPresignedURLMutation,
  useGetApplicationManageDataQuery,
  useLazyGetApplicationManageDataQuery,
  useUpdateCandidateDocumentIdToCDSMutation,
  useGetCandidateDataQuery,
  useLazyGetCandidateDataQuery,
  useGetCandidateAtoZStatusQuery,
  useLazyGetCandidateAtoZStatusQuery,
  useGetDocumentStatusInfoQuery,
  useLazyGetDocumentStatusInfoQuery,
  useLazyGetJobPreviewVideoDetailsQuery,
  useGetJobPreviewVideoDetailsQuery,
  useUpdateJobPreviewVideoDetailsMutation,
  useGetCaasInfoQuery,
  useGetCassValidCandidateContingenciesStatusQuery,
  useLazyGetCassValidCandidateContingenciesStatusQuery,
  useGetZapposMagicLinkQuery,
  useLazyGetZapposMagicLinkQuery,
  useLazyGetCaasInfoQuery,
  useLazyGetCandidateHealthQuery,
  useLazyGetOldDocUploadValidatorQuery,
  useWithDrawApplicationMutation,
  useUpdateCDSDocumentUploadMetadataMutation,
  useMetricsSenderCreateOnFlushMutation,
  useLazyGetTaskStatusQuery,
  useLazyGetRTWStatusQuery,
  useLazyGetBGCheckStatusQuery,
  useLazyGetCaasStatusQuery,
  useLazyGetI9StatusQuery,
  useGetBgcStatusQuery,
  useLazyGetBgcStatusQuery,
  useGetDtStatusQuery,
  useLazyGetDtStatusQuery,
  useGetDtStatusWithSfCandidateIdQuery,
  useLazyGetDtStatusWithSfCandidateIdQuery,
  useGetCaasDtStatusQuery,
  useLazyGetCaasDtStatusQuery,
  useGetMcStatusQuery,
  useLazyGetMcStatusQuery,
  useSubmitBadgePhotoMutation,
  useSendMetricVideoRecordAshMutation,
  useSendMetricVideoRecordNonAshMutation,
  useTestCsrfMutation,
  useGetCsrfTokenQuery,
  useLazyGetCsrfTokenQuery,
  // tasks
  usePostTaskStatusWithBBIDMutation,
} = apiSlice;
