import React from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";
import { FlyOutRTWAppointmentStatusCard, bgcUseStatusText } from "../utilities/FlyOutStatusCard";
import { PreHireOrientationProps } from "./preHireOrientationButton";
import "./styles.scss";

export const PreHireOrientationFlyout = ({ status, shouldOverrideStatus }: PreHireOrientationProps) => {
  const bundle = useChecklistArb();

  const overrideStatus = shouldOverrideStatus ? shouldOverrideStatus[status] : status;

  const rtwDetailsMessageTitle = bundle.getMessage("Checklist-Flyout-RTW-Appointment-Details-Title");

  const rtwAppointmentDetailGeneralMessage =
    "For now we are just creating a skelton and the UI needs to be updated according to the status";

  return (
    <FlyOutRTWAppointmentStatusCard
      status={overrideStatus}
      descriptionText={bgcUseStatusText(NHEAppointmentStatusEnum.UNKNOWN)}
      headingText={rtwDetailsMessageTitle}
      messageText={rtwAppointmentDetailGeneralMessage}
    />
  );
};
