import { useParams } from "react-router-dom";
import { ALL_TASKS } from "../config/taskConfigConstants";
import { GetChecklistTasks, getTargetTaskGroup, hideTaskOnConfig, TaskGroup } from "../config/tasks";
import { buildUrl } from "../config/urls";
import { Stage } from "../helpers/get-settings";
import { isDragonstone } from "../helpers/url-params";
import { badgePhotoAllowedStateForJapan, hasSkipAppointmentOneEligibility, isAlumni } from "../helpers/utils";
import { useAppSelector } from "../reduxStore/reduxHooks";
import { getConfigData } from "../reduxStore/slices/configSlice";
import { getTaskStatus as getTaskStatusSlice, TaskKey } from "../reduxStore/slices/taskStatusSlice";
import { isCustomerService } from "../utility/app-config-helper";
import { getCountryForDomain } from "../utility/locale-helper";
import { createGroup } from "../utility/taskGroup";
import { useGetApplicationManageData } from "./apis/useGetApplicationManageData";
import { useChecklistArb } from "./use-arb";
import { useConfig } from "./useConfig";

type TasksListInfo = {
  groups: TaskGroup[];
  newTaskGroups?: TaskGroup[];
};

function isTaskKey(k?: string): k is TaskKey {
  return !!k && ALL_TASKS.includes(k as (typeof ALL_TASKS)[number]);
}

export const useTasksListInfo = ({ stage }: { stage?: Stage }): TasksListInfo => {
  const { appConfigLoaded } = useConfig();
  const { rtwAppointmentInfo, caasI9Status, caasRTWStatus } = useAppSelector(getConfigData);
  const applicationManageData = useGetApplicationManageData();
  const { applicationId, requisitionId } = useParams<{
    applicationId: string;
    requisitionId: string;
  }>();

  const skipAppointmentOneEligiblilty = hasSkipAppointmentOneEligibility(applicationManageData);
  const enableBadgePhoto = badgePhotoAllowedStateForJapan(applicationManageData);
  const isAlumniCandidate = isAlumni(applicationManageData);
  const bundle = useChecklistArb();
  const country = getCountryForDomain();

  const isEligibleForI9DocInsteadRTW = caasI9Status !== "SKIPPED";

  const { taskGroupForCustomerService, taskGroupForNewUI, taskGroupForSkipAppt1Eligible, matchingConfig } =
    GetChecklistTasks({
      bundle,
      applicationId,
      appConfigLoaded,
      country,
      rightToWorkAppointmentProps: rtwAppointmentInfo,
      workAuthStatus: isEligibleForI9DocInsteadRTW ? caasI9Status : caasRTWStatus,
      skipAppt1: skipAppointmentOneEligiblilty,
      enableBadgePhotoForJapan: enableBadgePhoto,
      isAlumniApplication: isAlumniCandidate,
    });

  const targetTaskGroup = getTargetTaskGroup({
    isCustomerService: isCustomerService(applicationId),
    isDragonstone: isDragonstone(requisitionId),
    skipAppointmentOne: skipAppointmentOneEligiblilty,
    taskGroups: {
      taskGroupForCustomerService,
      taskGroupForNewUI,
      taskGroupForSkipAppt1Eligible,
    },
  });

  const groups = targetTaskGroup.map((taskGroup) => {
    return {
      ...taskGroup,
      tasks: taskGroup.tasks.filter(hideTaskOnConfig).map((task) => ({
        ...task,
        titleStatusPath:
          task.titleStatusPath &&
          buildUrl(task.titleStatusPath, {
            stage,
            requisitionId,
            applicationId,
          }),
      })),
    };
  });

  const { preNheTasks, preTrainingTasks, preDayOneTasks } = matchingConfig.overrideValue;

  const newTaskGroups =
    stage &&
    ([preNheTasks, preTrainingTasks, preDayOneTasks]
      .filter(Boolean)
      .map((taskGroup) => createGroup(taskGroup, stage, requisitionId, applicationId, undefined)) as TaskGroup[]);

  return { groups, newTaskGroups };
};

export const useTaskGroupStatus = ({ group }: { group?: TaskGroup }) => {
  const taskStatusInfo = useAppSelector(getTaskStatusSlice);

  const taskNames = group?.tasks?.map(({ taskName }) => taskName).filter(isTaskKey);

  const out: typeof taskStatusInfo = {};

  for (const taskName of taskNames ?? []) {
    out[taskName] = taskStatusInfo[taskName];
  }

  return out;
};
