import { useEffect } from "react";
import { useLazyGetRvpWidgetDetailsQuery } from "../../reduxStore/api/apiSlice";
import { useAppDispatch, useAppSelector } from "../../reduxStore/reduxHooks";
import { getConfigData, setConfigData } from "../../reduxStore/slices/configSlice";
import { CONFIG_DATA_KEY } from "../../utility/enums/common";
import { getLocale } from "../../utility/locale-helper";
import { useGetApplicationId } from "./useGetApplicationId";

// eslint-disable-next-line import/no-unused-modules
export const useGetRvpWidgetDetails = () => {
  const { rvpWidgetDetails } = useAppSelector(getConfigData);
  const applicationId = useGetApplicationId();
  const dispatch = useAppDispatch();
  const [getRvpWidgetDetails] = useLazyGetRvpWidgetDetailsQuery();

  useEffect(() => {
    if (rvpWidgetDetails) return;

    if (applicationId) {
      getRvpWidgetDetails(
        {
          applicationId,
          locale: getLocale(),
        },
        true
      )
        .unwrap()
        .then((data) => {
          dispatch(
            setConfigData({
              key: CONFIG_DATA_KEY.RVP_WIDGET_DETAILS,
              value: data,
            })
          );
        });
    }
  }, [applicationId, dispatch, getRvpWidgetDetails, rvpWidgetDetails]);

  return rvpWidgetDetails;
};
