import { Button, ButtonVariant } from "@amzn/stencil-react-components/button";
import { Col } from "@amzn/stencil-react-components/layout";
import React, { useCallback } from "react";
import { useChecklistArb } from "../../hooks/use-arb";
import { StatusEnum } from "../../utility/enums/common";
import { NHEAppointmentStatusEnum } from "../appointment-card/card-controlled";

export interface ReviewFinalOfferLetterProps {
  status: StatusEnum | NHEAppointmentStatusEnum;
  shouldOverrideStatus?: Record<string, StatusEnum>;
}

export const ReviewFinalOfferLetterButton = ({ status, shouldOverrideStatus }: ReviewFinalOfferLetterProps) => {
  const bundle = useChecklistArb();

  const redirect = useCallback(() => {
    // window.open("https://www.google.com/", "_blank");
  }, []);

  return (
    <div>
      {
        <Col>
          <Button
            data-testid="right-to-work-reschedule-button"
            variant={ButtonVariant.Primary}
            onClick={redirect}
            // disabled={}
          >
            {"View Offer Letter"}
          </Button>
        </Col>
      }
    </div>
  );
};
